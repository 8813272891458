import type { ResponsiveBreakpointNames } from '~/services/layout/layout';
import {
  getCellPluginConfigurationIds,
  responsiveBreakpointNames,
} from '~/services/layout/layout';
import type { FeedEntry, UIComponent } from '~/services/layout/index';
import get from 'lodash.get';
import logger from '~/services/logger';
import { twMerge } from 'tailwind-merge';
import { type CellPluginIdentifier } from '~/services/layout/get-cell-config';
import { applyDateTransformOnTextLabels } from './date-transform';
import { type ButtonNumber } from './buttons-container-getters';

export type Identifiers = {
  mobile: CellPluginIdentifier;
  tablet: CellPluginIdentifier;
  desktop: CellPluginIdentifier;
  'large-desktop': CellPluginIdentifier;
};

export type CellTypeResponsiveBreakpointValues = {
  cell_1_v2?: string[];
  cell_2_v2?: string[];
  cell_3_v2?: string[];
  cell_info_mobile?: string[];
};

export const cellTextLabels = {
  cell_1_v2: ['text_label_1', 'text_label_2', 'text_label_3'],
  cell_2_v2: ['text_label_1', 'text_label_2', 'text_label_3', 'text_label_4'],
  cell_3_v2: [
    'top_text_label_1',
    'top_text_label_2',
    'top_text_label_3',
    'center_text_label_1',
    'center_text_label_2',
    'center_text_label_3',
    'bottom_text_label_1',
    'bottom_text_label_2',
    'bottom_text_label_3',
  ],
  cell_info_mobile: ['button_text', 'title_text', 'title'],
};

export type ResponsiveBooleans = {
  mobile: boolean;
  tablet: boolean;
  desktop: boolean;
  'large-desktop': boolean;
};

export type ResponsiveSources = {
  mobile: string | boolean | undefined;
  tablet: string | boolean | undefined;
  desktop: string | boolean | undefined;
  'large-desktop': string | boolean | undefined;
};

/**
 * Get the values for the cell at each breakpoint
 * @param config - the styles or assets for each breakpoint
 * @param key - the key for the value
 * @param prevBreakpointFallback - whether to fall back to the previous breakpoint if the current breakpoint is not set
 * @returns the values for the cell at each breakpoint
 */
export function getCellBreakpointValues({
  config,
  key,
  prevBreakpointFallback,
}: {
  config: any;
  key: string;
  prevBreakpointFallback?: boolean;
}): ResponsiveSources {
  const responsiveValues: ResponsiveSources = {
    mobile: get(config.mobile, key),
    tablet: get(config.tablet, key),
    desktop: get(config.desktop, key),
    'large-desktop': get(config['large-desktop'], key),
  };

  if (!prevBreakpointFallback) return responsiveValues;

  return fallBackToPreviousBreakpoint(responsiveValues);
}

export type CellTextLabelsV2 = {
  mobile: Record<string, string | boolean | undefined>;
  tablet: Record<string, string | boolean | undefined>;
  desktop: Record<string, string | boolean | undefined>;
  'large-desktop': Record<string, string | boolean | undefined>;
};

export type GetCellTextLabelsV2Args = {
  entry: FeedEntry;
  styles: any;
  identifiers: Identifiers;
  timeZoneHeader: string | null;
  isLoading?: boolean;
};
/**
 * Get the text labels for the cell at each breakpoint
 * @param entry - the entry for the cell
 * @param styles - the cell styles for each breakpoint
 * @param identifiers - an object with keys mobile, tablet, desktop, and large-desktop
 * @param timeZoneHeader - the time zone header
 * @returns the text labels for the cell at each breakpoint
 */
export function getCellTextLabelsV2({
  entry,
  styles,
  identifiers,
  timeZoneHeader,
  isLoading,
}: GetCellTextLabelsV2Args): CellTextLabelsV2 {
  const textLabels: CellTextLabelsV2 = {
    mobile: {},
    tablet: {},
    desktop: {},
    'large-desktop': {},
  };

  assignTextLabels(cellTextLabels[identifiers.mobile], styles.mobile, 'mobile');
  assignTextLabels(cellTextLabels[identifiers.tablet], styles.tablet, 'tablet');
  assignTextLabels(
    cellTextLabels[identifiers.desktop],
    styles.desktop,
    'desktop'
  );
  assignTextLabels(
    cellTextLabels[identifiers['large-desktop']],
    styles['large-desktop'],
    'large-desktop'
  );

  function assignTextLabels(
    cellTextLabels: string[],
    breakpointStyles: any,
    breakpoint: ResponsiveBreakpointNames
  ) {
    try {
      cellTextLabels.forEach((label: string) => {
        const labelSwitch: boolean | undefined = get(
          breakpointStyles,
          `${label}_switch`
        );

        if (!labelSwitch) {
          textLabels[breakpoint][label] = undefined;
          return;
        }

        const customDataKey: string = get(
          breakpointStyles,
          `${label}_custom_data_key`
        );

        const dataKey = get(breakpointStyles, `${label}_data_key`);
        const isOther = dataKey === 'other';
        const key = isOther && customDataKey ? customDataKey : dataKey;

        let textValue: string | undefined = get(entry, key);

        if (identifiers[breakpoint] === 'cell_info_mobile' && !textValue) {
          if (dataKey === 'More') {
            const extensionsMore = get(entry, 'extensions.more');
            textValue = extensionsMore || dataKey;
          }

          if (isOther) textValue = customDataKey;
        }

        textLabels[breakpoint][label] = isLoading && key ? true : textValue;
      });
    } catch (error: any) {
      logger.info(`Cell Text Labels V2: ${error.message}`);
    }
  }

  return applyDateTransformOnTextLabels({
    textLabels,
    styles,
    timeZoneHeader,
  });
}

/**
 * Returns a string of cell classes based on the provided UIComponent cell IDs
 * @param uiComponent - the UIComponent object
 * @returns string
 * @example - cell-v2-mobile-cellid cell-v2-tablet-cellid etc.
 */
export function getCellClasses(uiComponent: UIComponent): string {
  const { mobileCellId, tabletCellId, desktopCellId, largeDesktopCellId } =
    getCellPluginConfigurationIds(uiComponent);

  const classes = [
    ['mobile', mobileCellId],
    ['tablet', tabletCellId],
    ['desktop', desktopCellId],
    ['large-desktop', largeDesktopCellId],
  ]
    .map((classDataArray) => {
      const [breakpointName, breakpointId] = classDataArray;
      if (!breakpointId) return '';
      return `cell-v2-${breakpointName}-${breakpointId}`;
    })
    .join(' ');

  return classes;
}

export type InfoCellClasses = {
  button: string;
  buttonIcon: string;
  buttonText: string;
  text: string;
};

export function getInfoCellClasses(cellClasses: string): InfoCellClasses {
  try {
    const cellClassNames = cellClasses.split(' ');

    const buttonSuffix = '-button';
    const buttonIconSuffix = '-button-icon';
    const buttonTextSuffix = '-button-text';
    const textSuffix = '-text';

    const infoCellClasses: any = {};

    infoCellClasses.button = cellClassNames
      .map((cellClassName: string) => {
        return `${cellClassName}${buttonSuffix}`;
      })
      .join(' ');

    infoCellClasses.buttonIcon = cellClassNames
      .map((cellClassName: string) => {
        return `${cellClassName}${buttonIconSuffix}`;
      })
      .join(' ');

    infoCellClasses.buttonText = cellClassNames
      .map((cellClassName: string) => {
        return `${cellClassName}${buttonTextSuffix}`;
      })
      .join(' ');

    infoCellClasses.text = cellClassNames
      .map((cellClassName: string) => {
        return `${cellClassName}${textSuffix}`;
      })
      .join(' ');

    return infoCellClasses;
  } catch (error: any) {
    logger.info(`getInfoCellClasses: ${error.message}`);
    return {
      button: '',
      buttonIcon: '',
      buttonText: '',
      text: '',
    };
  }
}

/**
 * Returns a string of text label classes based on the provided cell class names and label
 * @param cellsClassNames - the cell class names, for example cell-v2-mobile-cellid cell-v2-tablet-cellid etc.
 * @returns an object with the text label classes for each label
 * @example - text_label_1: cell-v2-mobile-cellid-text_label_1 cell-v2-tablet-cellid-text_label_1 etc.
 */
export function getCellTextLabelClasses(cellsClassNames: string) {
  const textLabelKeyNames = [
    ...cellTextLabels.cell_2_v2,
    ...cellTextLabels.cell_3_v2,
  ];

  const allTextLabelClasses: any = {};

  textLabelKeyNames.forEach((label: string) => {
    const textLabelClasses: string[] = [];

    cellsClassNames.split(' ').forEach((cellClassName) => {
      textLabelClasses.push(`${cellClassName}-${label}`);
    });

    allTextLabelClasses[label] = textLabelClasses.join(' ');
  });

  return allTextLabelClasses;
}

export type GetCellAssetSourcesArgs = {
  assets: any;
  assetKey: string;
  assetSwitchKey?: string;
  defaultThemeV2Config?: any;
};

/**
 * Get the image sources for all breakpoints of a cell
 * @param assets - cell assets for all breakpoints
 * @param assetKey - the key for the asset
 * @param assetSwitchKey - the key for the asset switch
 * @param defaultThemeV2Config - optionally pass in the defaultThemeV2Config to check for the asset
 * @returns for all breakpoints, the image sources or an empty string if the asset switch is off or the asset is not found
 */
export function getCellAssetSources({
  assets,
  assetKey,
  assetSwitchKey,
  defaultThemeV2Config,
}: GetCellAssetSourcesArgs): ResponsiveSources {
  const breakpoints = [
    { config: assets?.mobile, breakpoint: 'mobile' },
    { config: assets?.tablet, breakpoint: 'tablet' },
    { config: assets?.desktop, breakpoint: 'desktop' },
    { config: assets?.['large-desktop'], breakpoint: 'large-desktop' },
  ];

  const responsiveSources: ResponsiveSources = breakpoints.reduce(
    (result: ResponsiveSources, { config, breakpoint }) => {
      const assetSwitch: boolean = get(config, `${assetSwitchKey}`);
      if (typeof assetSwitch !== 'undefined' && assetSwitch === false)
        return result;

      const value =
        get(config, `${assetKey}`) ||
        get(defaultThemeV2Config, `${assetKey}`) ||
        '';
      return { ...result, [breakpoint]: value };
    },
    { mobile: '', tablet: '', desktop: '', 'large-desktop': '' }
  );

  return responsiveSources;
}

/**
 * Get the visibility classes for each breakpoint
 * @param switches - the switches for each breakpoint
 * @param classNames - optional additional classes
 * @returns - the visibility classes for each breakpoint
 */
export function getCellVisibillityClasses(
  switches: {
    mobile: any;
    tablet: any;
    desktop: any;
    'large-desktop': any;
  },
  classNames?: string
) {
  return twMerge(
    !switches.mobile && 'mobile:max-tablet:hidden',
    !switches.tablet && 'tablet:max-desktop:hidden',
    !switches.desktop && 'desktop:max-large-desktop:hidden',
    !switches['large-desktop'] && 'large-desktop:hidden',
    classNames || ''
  );
}

export function getInfoCellFlex1Classes(
  titleTextAlignment: AlignmentResponsive
) {
  return twMerge(
    titleTextAlignment.mobile === 'center' && 'mobile:max-tablet:flex-1',
    titleTextAlignment.tablet === 'center' && 'tablet:max-desktop:flex-1',
    titleTextAlignment.desktop === 'center' &&
      'desktop:max-large-desktop:flex-1',
    titleTextAlignment['large-desktop'] === 'center' && 'large-desktop:flex-1'
  );
}

export function getCellVisibillityClassesFromConfig({
  config,
  key,
}: {
  config: any;
  key: string;
}) {
  const switches = getCellBreakpointValues({
    config,
    key,
  });

  return twMerge(
    !switches.mobile && 'mobile:max-tablet:hidden',
    !switches.tablet && 'tablet:max-desktop:hidden',
    !switches.desktop && 'desktop:max-large-desktop:hidden',
    !switches['large-desktop'] && 'large-desktop:hidden'
  );
}

/**
 * Get the image sources for the cell at each breakpoint
 * @param assets - the assets for the cell at each breakpoint
 * @param entry - the entry for the cell
 * @returns - the image sources for the cell at each breakpoint while falling
 * back to the previous breakpoint if the current breakpoint is not set.
 */
export function getCellEntryImgSources({
  assets,
  entry,
}: {
  assets: any;
  entry: FeedEntry;
}): ResponsiveSources {
  try {
    const payloadImgSources: any = {};

    responsiveBreakpointNames.forEach((breakpoint: string) => {
      const imageSwitch: boolean | undefined = assets[breakpoint]?.image_switch;

      if (typeof imageSwitch !== 'undefined' && imageSwitch === false)
        return undefined;

      const imageSource: string | undefined =
        getMediaItemSrc({
          entry,
          assets,
          breakpoint,
        }) || assets[breakpoint]?.image_placeholder;

      payloadImgSources[breakpoint] = imageSource;
    });

    return payloadImgSources;
  } catch (error: any) {
    logger.info(`getCellEntryImgSources: ${error.message}`);
    return {
      mobile: '',
      tablet: '',
      desktop: '',
      'large-desktop': '',
    };
  }
}

/**
 * Get the media item src based on the image_key
 * If the image_key is not found, return the first media item src
 * @returns - the media item src or undefined if not found
 */
function getMediaItemSrc({
  entry,
  assets,
  breakpoint,
}: {
  entry: any;
  assets: any;
  breakpoint: string;
}): string | undefined {
  try {
    return (
      entry.media_group[0].media_item.find((mediaItem: any) => {
        return mediaItem.key === assets[breakpoint]?.image_key;
      })?.src || entry.media_group[0].media_item[0].src
    );
  } catch (error) {
    return undefined;
  }
}

/**
 * Get the power cell container classes for each cell type at each breakpoint
 * @param identifiers - an object with keys mobile, tablet, desktop, and large-desktop
 * @returns - the power cell container classes for each cell type at each breakpoint
 */
export function getCellContainerCellTypeClasses({
  identifiers,
}: {
  identifiers: Identifiers;
}) {
  return twMerge(
    'pointer-events-none',
    identifiers.mobile === 'cell_1_v2' &&
      'mobile:max-tablet:group-[.cell-1-mobile]:flex',
    identifiers.tablet === 'cell_1_v2' &&
      'tablet:max-desktop:group-[.cell-1-tablet]:flex',
    identifiers.desktop === 'cell_1_v2' &&
      'desktop:max-large-desktop:group-[.cell-1-desktop]:flex',
    identifiers['large-desktop'] === 'cell_1_v2' &&
      'large-desktop:group-[.cell-1-large-desktop]:flex',
    identifiers.mobile === 'cell_1_v2' &&
      'mobile:max-tablet:group-[.cell-1-mobile]:flex-col',
    identifiers.tablet === 'cell_1_v2' &&
      'tablet:max-desktop:group-[.cell-1-tablet]:flex-col',
    identifiers.desktop === 'cell_1_v2' &&
      'desktop:max-large-desktop:group-[.cell-1-desktop]:flex-col',
    identifiers['large-desktop'] === 'cell_1_v2' &&
      'large-desktop:group-[.cell-1-large-desktop]:flex-col',

    identifiers.mobile === 'cell_2_v2' &&
      'mobile:max-tablet:group-[.cell-2-mobile]:flex',
    identifiers.tablet === 'cell_2_v2' &&
      'tablet:max-desktop:group-[.cell-2-tablet]:flex',
    identifiers.desktop === 'cell_2_v2' &&
      'desktop:max-large-desktop:group-[.cell-2-desktop]:flex',
    identifiers['large-desktop'] === 'cell_2_v2' &&
      'large-desktop:group-[.cell-2-large-desktop]:flex',

    identifiers.mobile === 'cell_3_v2' &&
      'mobile:max-tablet:group-[.cell-3-mobile]:relative',
    identifiers.tablet === 'cell_3_v2' &&
      'tablet:max-desktop:group-[.cell-3-tablet]:relative',
    identifiers.desktop === 'cell_3_v2' &&
      'desktop:max-large-desktop:group-[.cell-3-desktop]:relative',
    identifiers['large-desktop'] === 'cell_3_v2' &&
      'large-desktop:group-[.cell-3-large-desktop]:relative'
  );
}

export function getCellImageContainerClasses({
  identifiers,
  assets,
}: {
  identifiers: Identifiers;
  assets: any;
}) {
  const cell2ImageSwitch: ResponsiveBooleans = {
    mobile: !!assets.mobile?.image_switch,
    tablet: !!assets.tablet?.image_switch,
    desktop: !!assets.desktop?.image_switch,
    'large-desktop': !!assets['large-desktop']?.image_switch,
  };

  const mobile: boolean =
    identifiers.mobile === 'cell_2_v2' && cell2ImageSwitch.mobile;
  const tablet: boolean =
    identifiers.tablet === 'cell_2_v2' && cell2ImageSwitch.tablet;
  const desktop: boolean =
    identifiers.desktop === 'cell_2_v2' && cell2ImageSwitch.desktop;
  const largeDesktop: boolean =
    identifiers['large-desktop'] === 'cell_2_v2' &&
    cell2ImageSwitch['large-desktop'];

  return twMerge(
    'mobile:max-tablet:group-[.cell-1-mobile]:mt-image-margin-top',
    'mobile:max-tablet:group-[.cell-1-mobile]:mr-image-margin-right',
    'mobile:max-tablet:group-[.cell-1-mobile]:mb-image-margin-bottom',
    'mobile:max-tablet:group-[.cell-1-mobile]:ml-image-margin-left',

    'tablet:max-desktop:group-[.cell-1-tablet]:mt-image-margin-top',
    'tablet:max-desktop:group-[.cell-1-tablet]:mr-image-margin-right',
    'tablet:max-desktop:group-[.cell-1-tablet]:mb-image-margin-bottom',
    'tablet:max-desktop:group-[.cell-1-tablet]:ml-image-margin-left',

    'desktop:max-large-desktop:group-[.cell-1-desktop]:mt-image-margin-top',
    'desktop:max-large-desktop:group-[.cell-1-desktop]:mr-image-margin-right',
    'desktop:max-large-desktop:group-[.cell-1-desktop]:mb-image-margin-bottom',
    'desktop:max-large-desktop:group-[.cell-1-desktop]:ml-image-margin-left',

    'large-desktop:group-[.cell-1-large-desktop]:mt-image-margin-top',
    'large-desktop:group-[.cell-1-large-desktop]:mr-image-margin-right',
    'large-desktop:group-[.cell-1-large-desktop]:mb-image-margin-bottom',
    'large-desktop:group-[.cell-1-large-desktop]:ml-image-margin-left',

    mobile && 'mobile:max-tablet:group-[.cell-2-mobile]:h-fit',
    mobile && 'mobile:max-tablet:group-[.cell-2-mobile]:mt-image-margin-top',
    mobile && 'mobile:max-tablet:group-[.cell-2-mobile]:mr-image-margin-right',
    mobile && 'mobile:max-tablet:group-[.cell-2-mobile]:mb-image-margin-bottom',
    mobile && 'mobile:max-tablet:group-[.cell-2-mobile]:ml-image-margin-left',

    tablet && 'tablet:max-desktop:group-[.cell-2-tablet]:h-fit',
    tablet && 'tablet:max-desktop:group-[.cell-2-tablet]:mt-image-margin-top',
    tablet && 'tablet:max-desktop:group-[.cell-2-tablet]:mr-image-margin-right',
    tablet &&
      'tablet:max-desktop:group-[.cell-2-tablet]:mb-image-margin-bottom',
    tablet && 'tablet:max-desktop:group-[.cell-2-tablet]:ml-image-margin-left',

    desktop && 'desktop:max-large-desktop:group-[.cell-2-desktop]:h-fit',
    desktop &&
      'desktop:max-large-desktop:group-[.cell-2-desktop]:mt-image-margin-top',
    desktop &&
      'desktop:max-large-desktop:group-[.cell-2-desktop]:mr-image-margin-right',
    desktop &&
      'desktop:max-large-desktop:group-[.cell-2-desktop]:mb-image-margin-bottom',
    desktop &&
      'desktop:max-large-desktop:group-[.cell-2-desktop]:ml-image-margin-left',

    largeDesktop && 'large-desktop:group-[.cell-2-large-desktop]:h-fit',
    largeDesktop &&
      'large-desktop:group-[.cell-2-large-desktop]:mt-image-margin-top',
    largeDesktop &&
      'large-desktop:group-[.cell-2-large-desktop]:mr-image-margin-right',
    largeDesktop &&
      'large-desktop:group-[.cell-2-large-desktop]:mb-image-margin-bottom',
    largeDesktop &&
      'large-desktop:group-[.cell-2-large-desktop]:ml-image-margin-left'
  );
}

export function getCellHiddenClasses({
  identifiers,
  identifier,
  classNames,
}: {
  identifiers: Identifiers;
  identifier: CellPluginIdentifier;
  classNames?: string;
}): string {
  const groupClassesPrefix = {
    cell_1_v2: 'cell-1',
    cell_2_v2: 'cell-2',
    cell_3_v2: 'cell-3',
    cell_info_mobile: 'cell-info',
  };

  return twMerge(
    identifiers.mobile !== identifier &&
      `mobile:max-tablet:group-[.${groupClassesPrefix[identifier]}-mobile]:hidden`,
    identifiers.tablet !== identifier &&
      `tablet:max-desktop:group-[.${groupClassesPrefix[identifier]}-tablet]:hidden`,
    identifiers.desktop !== identifier &&
      `desktop:max-large-desktop:group-[.${groupClassesPrefix[identifier]}-desktop]:hidden`,
    identifiers['large-desktop'] !== identifier &&
      `large-desktop:group-[.${groupClassesPrefix[identifier]}-large-desktop]:hidden`,
    classNames || ''
  );
}

/**
 * Get the cell 3 text label container classes for each breakpoint
 * Used to hide cell 3 text labels when cell 3 is NOT on the current breakpoint
 * @param identifiers - an object with keys mobile, tablet, desktop, and large-desktop
 * @param classNames - the class names to merge with the cell 3 text label container
 * @returns - the cell 3 text label container classes for each breakpoint
 */
export function getCell3TextLabelContainerClasses({
  identifiers,
  classNames,
}: {
  identifiers: ResponsiveSources;
  classNames: string;
}) {
  return twMerge(
    classNames,
    identifiers.mobile !== 'cell_3_v2' &&
      'mobile:max-tablet:group-[.cell-3-mobile]:hidden',
    identifiers.tablet !== 'cell_3_v2' &&
      'tablet:max-desktop:group-[.cell-3-tablet]:hidden',
    identifiers.desktop !== 'cell_3_v2' &&
      'desktop:max-large-desktop:group-[.cell-3-desktop]:hidden',
    identifiers['large-desktop'] !== 'cell_3_v2' &&
      'large-desktop:group-[.cell-3-large-desktop]:hidden'
  );
}

/**
 * Get the cell image cell type classes for each cell type at each breakpoint
 * @param cellClasses - the cell classes of the power cell, cell-v2-mobile-cellid cell-v2-tablet-cellid etc.
 * @param identifiers - an object with keys mobile, tablet, desktop, and large-desktop
 * @returns - the cell image cell type classes for each cell type at each breakpoint
 */
export function getCellImageCellTypeClasses({
  cellClasses,
  identifiers,
}: {
  cellClasses: string;
  identifiers: ResponsiveSources;
}) {
  return twMerge(
    cellClasses,
    'aspect-cell-image object-cover rounded-image-radius',

    identifiers.mobile === 'cell_1_v2' &&
      'mobile:max-tablet:group-[.cell-1-mobile]:w-full',
    identifiers.tablet === 'cell_1_v2' &&
      'tablet:max-desktop:group-[.cell-1-tablet]:w-full',
    identifiers.desktop === 'cell_1_v2' &&
      'desktop:max-large-desktop:group-[.cell-1-desktop]:w-full',
    identifiers['large-desktop'] === 'cell_1_v2' &&
      'large-desktop:group-[.cell-1-large-desktop]:w-full',

    identifiers.mobile === 'cell_2_v2' &&
      'mobile:max-tablet:group-[.cell-2-mobile]:w-image-width',
    identifiers.tablet === 'cell_2_v2' &&
      'tablet:max-desktop:group-[.cell-2-tablet]:w-image-width',
    identifiers.desktop === 'cell_2_v2' &&
      'desktop:max-large-desktop:group-[.cell-2-desktop]:w-image-width',
    identifiers['large-desktop'] === 'cell_2_v2' &&
      'large-desktop:group-[.cell-2-large-desktop]:w-image-width',

    identifiers.mobile === 'cell_3_v2' &&
      'mobile:max-tablet:group-[.cell-3-mobile]:w-full',
    identifiers.tablet === 'cell_3_v2' &&
      'tablet:max-desktop:group-[.cell-3-tablet]:w-full',
    identifiers.desktop === 'cell_3_v2' &&
      'desktop:max-large-desktop:group-[.cell-3-desktop]:w-full',
    identifiers['large-desktop'] === 'cell_3_v2' &&
      'large-desktop:group-[.cell-3-large-desktop]:w-full'
  );
}

/**
 * Fall back to the previous breakpoint if the current breakpoint is not set
 * @param ResponsiveSources - an object with keys mobile, tablet, desktop, and large-desktop
 * @returns ResponsiveSources
 */
function fallBackToPreviousBreakpoint({
  mobile,
  tablet,
  desktop,
  'large-desktop': largeDesktop,
}: ResponsiveSources): ResponsiveSources {
  if (!tablet) tablet = mobile;
  if (!desktop) desktop = tablet;
  if (!largeDesktop) largeDesktop = desktop;

  return {
    mobile,
    tablet,
    desktop,
    'large-desktop': largeDesktop,
  };
}

export function getCellImagePlaceholderColorClasses({
  assets,
  identifiers,
  imageSources,
}: {
  assets: any;
  identifiers: Identifiers;
  imageSources: ResponsiveSources;
}): string {
  const {
    isMobileCellImage,
    isTabletCellImage,
    isDesktopCellImage,
    isLargeDesktopCellImage,
  } = getCellImageConditions({ assets, identifiers });

  return twMerge(
    isMobileCellImage &&
      !imageSources.mobile &&
      'mobile:max-tablet:bg-image-placeholder',
    isTabletCellImage &&
      !imageSources.tablet &&
      'tablet:max-desktop:bg-image-placeholder',
    isDesktopCellImage &&
      !imageSources.desktop &&
      'desktop:max-large-desktop:bg-image-placeholder',
    isLargeDesktopCellImage &&
      !imageSources['large-desktop'] &&
      'large-desktop:bg-image-placeholder'
  );
}

export function getCellImageAspectRatioClasses({
  assets,
  identifiers,
}: {
  assets: any;
  identifiers: Identifiers;
}): string {
  const {
    isMobileCellImage,
    isTabletCellImage,
    isDesktopCellImage,
    isLargeDesktopCellImage,
  } = getCellImageConditions({ assets, identifiers });

  return twMerge(
    isMobileCellImage && 'mobile:max-tablet:aspect-cell-image',
    isTabletCellImage && 'tablet:max-desktop:aspect-cell-image',
    isDesktopCellImage && 'desktop:max-large-desktop:aspect-cell-image',
    isLargeDesktopCellImage && 'large-desktop:aspect-cell-image'
  );
}

/**
 * Get the conditions for the cell image.
 * Return false for each breakpoint if:
 * - the cell type is cell_2_v2 and the image switch is off
 * - the cell type is cell_info_mobile
 */
export function getCellImageConditions({
  assets,
  identifiers,
}: {
  assets: any;
  identifiers: Identifiers;
}): {
  isMobileCellImage: boolean;
  isTabletCellImage: boolean;
  isDesktopCellImage: boolean;
  isLargeDesktopCellImage: boolean;
} {
  const isMobileCellImage: boolean = !(
    (identifiers.mobile === 'cell_2_v2' && !assets.mobile?.image_switch) ||
    identifiers.mobile === 'cell_info_mobile'
  );

  const isTabletCellImage: boolean = !(
    (identifiers.tablet === 'cell_2_v2' && !assets.tablet?.image_switch) ||
    identifiers.tablet === 'cell_info_mobile'
  );

  const isDesktopCellImage: boolean = !(
    (identifiers.desktop === 'cell_2_v2' && !assets.desktop?.image_switch) ||
    identifiers.desktop === 'cell_info_mobile'
  );

  const isLargeDesktopCellImage: boolean = !(
    (identifiers['large-desktop'] === 'cell_2_v2' &&
      !assets['large-desktop']?.image_switch) ||
    identifiers['large-desktop'] === 'cell_info_mobile'
  );

  return {
    isMobileCellImage,
    isTabletCellImage,
    isDesktopCellImage,
    isLargeDesktopCellImage,
  };
}

export type GetResponsiveCellValuesArgs = {
  cellKeys: CellTypeResponsiveBreakpointValues;
  cellKeysNameSuffix: string;
  config: any;
  identifiers: Identifiers;
};
/**
 * Get the values for the cell at each breakpoint
 * @param cellKeysArray - an object with keys mobile, tablet, desktop, and large-desktop
 * @param cellKeysNameSuffix - the suffix for the cell key name
 * @param config - the styles or assets for each breakpoint
 * @param identifiers - an object with keys mobile, tablet, desktop, and large-desktop
 */
export function getResponsiveCellValues({
  cellKeys,
  cellKeysNameSuffix,
  config,
  identifiers,
}: GetResponsiveCellValuesArgs): {
  mobile: Record<string, string>;
  tablet: Record<string, string>;
  desktop: Record<string, string>;
  'large-desktop': Record<string, string>;
} {
  try {
    const responsiveCellValues: any = {
      mobile: {},
      tablet: {},
      desktop: {},
      'large-desktop': {},
    };

    responsiveBreakpointNames.forEach(
      (breakpoint: ResponsiveBreakpointNames) => {
        const identifier: CellPluginIdentifier = identifiers[breakpoint];

        const breakpointCellKeysArray: string[] | undefined =
          cellKeys[identifier];
        if (!breakpointCellKeysArray) return;

        breakpointCellKeysArray.forEach((cellKeyName: string) => {
          const keyName: string = `${cellKeyName}_${cellKeysNameSuffix}`;
          const keyValue: string = config[breakpoint][keyName];

          responsiveCellValues[breakpoint][cellKeyName] = keyValue;
        });
      }
    );

    return responsiveCellValues;
  } catch (error: any) {
    logger.info(`getResponsiveCellValues: ${error.message}`);
    return {
      mobile: {},
      tablet: {},
      desktop: {},
      'large-desktop': {},
    };
  }
}

export type AlignmentOptions = 'left' | 'center' | 'right';
export type AlignmentResponsive = {
  mobile: AlignmentOptions;
  tablet: AlignmentOptions;
  desktop: AlignmentOptions;
  'large-desktop': AlignmentOptions;
};

export function getTextAlignmentMarginClasses({
  mobile,
  tablet,
  desktop,
  'large-desktop': largeDesktop,
}: AlignmentResponsive): string {
  return twMerge(
    mobile === 'center'
      ? `mobile:max-tablet:mx-auto`
      : mobile === 'right'
      ? `mobile:max-tablet:ml-auto`
      : `mobile:max-tablet:mr-auto`,

    tablet === 'center'
      ? `tablet:max-desktop:mx-auto`
      : tablet === 'right'
      ? `tablet:max-desktop:ml-auto`
      : `tablet:max-desktop:mr-auto`,

    desktop === 'center'
      ? `desktop:max-large-desktop:mx-auto`
      : desktop === 'right'
      ? `desktop:max-large-desktop:ml-auto`
      : `desktop:max-large-desktop:mr-auto`,

    largeDesktop === 'center'
      ? `large-desktop:mx-auto`
      : largeDesktop === 'right'
      ? `large-desktop:ml-auto`
      : `large-desktop:mr-auto`
  );
}

export function getTextAlignmentClasses({
  mobile,
  tablet,
  desktop,
  'large-desktop': largeDesktop,
}: AlignmentResponsive): string {
  return twMerge(
    mobile === 'center'
      ? `mobile:max-tablet:text-center`
      : mobile === 'right'
      ? `mobile:max-tablet:text-end`
      : `mobile:max-tablet:text-start`,

    tablet === 'center'
      ? `tablet:max-desktop:text-center`
      : tablet === 'right'
      ? `tablet:max-desktop:text-end`
      : `tablet:max-desktop:text-start`,

    desktop === 'center'
      ? `desktop:max-large-desktop:text-center`
      : desktop === 'right'
      ? `desktop:max-large-desktop:text-end`
      : `desktop:max-large-desktop:text-start`,

    largeDesktop === 'center'
      ? `large-desktop:text-center`
      : largeDesktop === 'right'
      ? `large-desktop:text-end`
      : `large-desktop:text-start`
  );
}

export function getPointerEventsClasses({
  assets,
  identifiers,
}: {
  assets: any;
  identifiers: Identifiers;
}): string {
  const buttonsContainerSwitches = getCellBreakpointValues({
    config: assets,
    key: 'buttons_container_switch',
  });

  const buttonPointerEventsClasses = (
    buttonNumber: ButtonNumber
  ): ResponsiveBooleans => {
    const buttonAssignActions = getCellBreakpointValues({
      config: assets,
      key: `button${buttonNumber}_assign_action`,
    });

    const buttonSwitches = getCellBreakpointValues({
      config: assets,
      key: `button${buttonNumber}_switch`,
    });

    const mobile: boolean =
      !!(
        buttonsContainerSwitches.mobile &&
        buttonSwitches.mobile &&
        buttonAssignActions.mobile === 'play'
      ) || identifiers.mobile === 'cell_info_mobile';

    const tablet: boolean =
      !!(
        buttonsContainerSwitches.tablet &&
        buttonSwitches.tablet &&
        buttonAssignActions.tablet === 'play'
      ) || identifiers.tablet === 'cell_info_mobile';

    const desktop: boolean =
      !!(
        buttonsContainerSwitches.desktop &&
        buttonSwitches.desktop &&
        buttonAssignActions.desktop === 'play'
      ) || identifiers.desktop === 'cell_info_mobile';

    const largeDesktop: boolean =
      !!(
        buttonsContainerSwitches['large-desktop'] &&
        buttonSwitches['large-desktop'] &&
        buttonAssignActions['large-desktop'] === 'play'
      ) || identifiers['large-desktop'] === 'cell_info_mobile';

    return {
      mobile,
      tablet,
      desktop,
      'large-desktop': largeDesktop,
    };
  };

  const mobile =
    buttonPointerEventsClasses(1).mobile ||
    buttonPointerEventsClasses(2).mobile ||
    buttonPointerEventsClasses(3).mobile;
  const tablet =
    buttonPointerEventsClasses(1).tablet ||
    buttonPointerEventsClasses(2).tablet ||
    buttonPointerEventsClasses(3).tablet;
  const desktop =
    buttonPointerEventsClasses(1).desktop ||
    buttonPointerEventsClasses(2).desktop ||
    buttonPointerEventsClasses(3).desktop;
  const largeDesktop =
    buttonPointerEventsClasses(1)['large-desktop'] ||
    buttonPointerEventsClasses(2)['large-desktop'] ||
    buttonPointerEventsClasses(3)['large-desktop'];

  return twMerge(
    mobile && 'mobile:max-tablet:pointer-events-none',
    tablet && 'tablet:max-desktop:pointer-events-none',
    desktop && 'desktop:max-large-desktop:pointer-events-none',
    largeDesktop && 'large-desktop:pointer-events-none'
  );
}

/**
 * Check if the power cell component should be rendered based on the config
 * @param config - the styles or assets for each breakpoint
 * @param key - the key for the value
 * @returns a boolean indicating whether the power cell component should be rendered
 */
export function shouldRenderByConfig({
  config,
  key,
}: {
  config: any;
  key: string;
}): boolean {
  const args = getCellBreakpointValues({
    config,
    key,
  });

  return [args.mobile, args.tablet, args.desktop, args['large-desktop']].some(
    (value) => !!value
  );
}

export type ShouldRenderByIdentifiersArgs = {
  identifiers: Identifiers;
  identifierName: CellPluginIdentifier;
};
/**
 * Check if the power cell component should be rendered based on identifiers
 * @param identifiers - an object with responsive keys indicating the cell type
 * @param identifierName - the cell type to check against
 * @returns a boolean indicating whether the power cell component should be rendered
 */
export function shouldRenderByIdentifiers({
  identifiers,
  identifierName,
}: ShouldRenderByIdentifiersArgs): boolean {
  return [
    identifiers.mobile,
    identifiers.tablet,
    identifiers.desktop,
    identifiers['large-desktop'],
  ].some((value) => value === identifierName);
}

/**
 * Check if the power cell text label component should be rendered based on the text label name.
 * If the text label is not set in any breakpoint, the component should not be rendered.
 * If the text label is set in any breakpoint, the component should be rendered then
 * the text label will be shown or hidden by css.
 * @param textLabels - the text labels for the cell at each breakpoint
 * @param textLabelName - the text label name to check against
 * @returns a boolean indicating whether the power cell component should be rendered
 */
export function shouldRenderPowerCellLabel({
  textLabels,
  textLabelName,
}: {
  textLabels: any;
  textLabelName: string;
}): boolean {
  const args: any = {};
  Object.keys(textLabels).forEach((key) => {
    args[key] = textLabels[key][textLabelName];
  });
  return [args.mobile, args.tablet, args.desktop, args['large-desktop']].some(
    (value) => !!value
  );
}

export function getCell3IgnorePaddingClasses(
  identifiers: Identifiers,
  position: 'label wrapper' | 'top' | 'bottom'
) {
  let ignorePaddingMarginClasses: string = '';

  if (position === 'label wrapper') {
    ignorePaddingMarginClasses =
      'ml-cell-3-ignore-padding-l mr-cell-3-ignore-padding-r';
  }

  if (position === 'top') {
    ignorePaddingMarginClasses = 'mt-cell-3-ignore-padding-t';
  }

  if (position === 'bottom') {
    ignorePaddingMarginClasses = 'mb-cell-3-ignore-padding-b';
  }

  return twMerge(
    identifiers.mobile === 'cell_3_v2' && ignorePaddingMarginClasses,
    identifiers.tablet === 'cell_3_v2' && ignorePaddingMarginClasses,
    identifiers.desktop === 'cell_3_v2' && ignorePaddingMarginClasses,
    identifiers['large-desktop'] === 'cell_3_v2' && ignorePaddingMarginClasses
  );
}

export function getCellImageHoverShadowClassesFromConfig({
  identifiers,
  assets,
}: {
  identifiers: Identifiers | undefined;
  assets: any;
}): string {
  if (!assets || !identifiers) return '';

  const imageShadowSwitch = getCellBreakpointValues({
    config: assets,
    key: 'image_shadow_switch',
  });

  const imageHoverShadowSwitch = getCellBreakpointValues({
    config: assets,
    key: 'image_hover_shadow_switch',
  });

  const isMobileCell3 = identifiers.mobile === 'cell_3_v2';
  const isTabletCell3 = identifiers.tablet === 'cell_3_v2';
  const isDesktopCell3 = identifiers.desktop === 'cell_3_v2';
  const isLargeDesktopCell3 = identifiers['large-desktop'] === 'cell_3_v2';

  return twMerge(
    imageShadowSwitch.mobile &&
      !isMobileCell3 &&
      'mobile:max-tablet:shadow-cell-image',
    imageShadowSwitch.tablet &&
      !isTabletCell3 &&
      'tablet:max-desktop:shadow-cell-image',
    imageShadowSwitch.desktop &&
      !isDesktopCell3 &&
      'desktop:max-large-desktop:shadow-cell-image',
    imageShadowSwitch['large-desktop'] &&
      !isLargeDesktopCell3 &&
      'large-desktop:shadow-cell-image',

    imageHoverShadowSwitch.mobile &&
      !isMobileCell3 &&
      'mobile:max-tablet:group-hover:shadow-cell-image-hover',
    imageHoverShadowSwitch.tablet &&
      !isTabletCell3 &&
      'tablet:max-desktop:group-hover:shadow-cell-image-hover',
    imageHoverShadowSwitch.desktop &&
      !isDesktopCell3 &&
      'desktop:max-large-desktop:group-hover:shadow-cell-image-hover',
    imageHoverShadowSwitch['large-desktop'] &&
      !isLargeDesktopCell3 &&
      'large-desktop:group-hover:shadow-cell-image-hover'
  );
}

export function getCellImageHiddenClasses({
  assets,
  identifiers,
}: {
  assets: any;
  identifiers: Identifiers;
}) {
  const {
    isMobileCellImage,
    isTabletCellImage,
    isDesktopCellImage,
    isLargeDesktopCellImage,
  } = getCellImageConditions({ assets, identifiers });

  return twMerge(
    !isMobileCellImage && 'mobile:max-tablet:hidden',
    !isTabletCellImage && 'tablet:max-desktop:hidden',
    !isDesktopCellImage && 'desktop:max-large-desktop:hidden',
    !isLargeDesktopCellImage && 'large-desktop:hidden'
  );
}
