import { useCallback, useRef, useState } from 'react';
import type { Feed, FeedEntry, UIComponent } from '~/services/layout/index.ts';
import { HorizontalListButton } from './button.tsx';
import {
  useDispachResizeEvent,
  useDuplicatedFeedEntry,
  useHorizontalListAutoScroll,
  useHorizontalListButtonPosition,
  useHorizontalListResize,
  useHorizontalListScroll,
  useUpdateIndicatorsIndexOnScroll,
  useHorizontalListSnap,
  useHorizontalListCircularScrollInstantScrollOnInitialLoad,
} from './hooks.ts';
import { useNextFeedPagination } from '~/hooks/use-next-feed.ts';
import { PowerCell } from '~/plugins/power-cell/components/index.tsx';
import HorizontalListIndicators from './indicators.tsx';
import manifest from '../config/manifest.ts';
import { getPluginConfig } from '~/utils/get-plugin-configuration.ts';
import { cn } from '~/components2/utils.ts';
import {
  getHorizontalListButtonsVisibility,
  getHorizontalListScrollSnapClasses,
} from './getters.ts';
import { getTranslations } from '~/services/localization/index.ts';
import { useFeedEntries } from '~/hooks/use-feed-entries.ts';
import { useHorizontalListResponsiveValues } from './use-horizontal-list-responsive-values.ts';
import { triggerScrollAction } from './trigger-scroll-action.ts';

export default function HorizontalListV2({
  uiComponent,
  feed,
  isLoading,
  isClient = false,
}: {
  uiComponent: UIComponent;
  feed?: Feed;
  isLoading?: boolean;
  isClient?: boolean;
}): JSX.Element {
  const ref = useRef<HTMLDivElement>(null);
  const cellContainerRef = useRef<HTMLDivElement>(null);
  const cellRef = useRef<HTMLAnchorElement>(null);

  const { styles, rules } = getPluginConfig<typeof manifest>(
    manifest,
    uiComponent
  );

  const [feedEntries, setFeedEntries] = useState<FeedEntry[]>(
    feed?.entry || []
  );

  const [toggleButtons, setToggleButtons] = useState({
    enabled: false,
    left: false,
    right: false,
    now: new Date().getTime(),
  });

  const [nextFeedUrl, setNextFeedUrl] = useState<string | undefined>(undefined);
  const [isNextFeedLoading, setIsNextFeedLoading] = useState(false);
  const [currentIndicatorsIndex, setCurrentIndicatorsIndex] =
    useState<number>(0);

  const [isScrolling, setIsScrolling] = useState(false);

  const [isEntriesLoading, setIsEntriesLoading] = useState<boolean>(true);

  const itemLimit: number | undefined = rules?.item_limit;

  // Temporary disable autoscroll & circular scroll
  const autoscrollSwitch: boolean = false;
  const loopSwitch: boolean = false;
  const circularCondition: boolean = false;
  // const circularCondition: boolean =
  //   rules?.circular_scroll_switch || (autoscrollSwitch && loopSwitch);

  const entries = useFeedEntries({
    feedEntries,
    setFeedEntries,
    isLoading,
    isClient,
    feed,
    itemLimit,
    circularCondition,
  });

  const {
    indicators_switch,
    indicator_arrows_switch,
    indicators_position,
    indicator_arrow_left_asset_default,
    indicator_arrow_right_asset_default,
    arrow_position,
  } = styles;

  const {
    gutter,
    paddingLeft,
    nextCellOffset,
    // autoscrollSwitch,
    autoscrollInterval,
    // loopSwitch,
    arrowsSwitch,
    cellScalingSwitch,
    indicatorsAlignment,
    hideButtonsBasedOnCellsInView,
  } = useHorizontalListResponsiveValues({
    rules,
    styles,
    entries,
  });

  const { showLeftButton, showRightButton } =
    getHorizontalListButtonsVisibility({
      ref,
      cellRef,
      feedEntriesLength: entries.length,
      circularCondition,
      isNextFeedLoading,
      gutter,
    });

  const horizontalListButtonPosition = useHorizontalListButtonPosition({
    isNextFeedLoading,
    arrow_position,
    ref,
    cellRef,
    position: arrow_position,
  });

  useHorizontalListScroll({
    ref,
    circularCondition,
    setToggleButtons,
    nextFeedUrl,
    isScrolling,
    setIsScrolling,
  });

  useHorizontalListResize({
    entries,
    ref,
    cellRef,
    nextFeedUrl,
    circularCondition,
    setToggleButtons,
    isLoading,
    gutter,
  });

  useNextFeedPagination({
    feed,
    nextFeedUrl,
    setNextFeedUrl,
    setIsNextFeedLoading,
    isNextFeedLoading,
    setFeedEntries,
    feedEntries,
    itemLimit,
    condition:
      !!nextFeedUrl &&
      toggleButtons.enabled &&
      !showRightButton &&
      !isLoading &&
      !autoscrollSwitch &&
      !indicators_switch,
    isLoading,
  });

  useHorizontalListResize({
    entries,
    ref,
    cellRef,
    nextFeedUrl,
    circularCondition,
    setToggleButtons,
    isLoading,
    gutter,
  });

  useDispachResizeEvent({
    ref,
    circularCondition,
    nextFeedUrl,
  });

  useHorizontalListSnap({
    ref,
    isScrolling,
  });

  const scrollLeft = useCallback(() => {
    if (!ref.current) return;

    ref.current.style.scrollSnapType = 'none';

    ref.current.scrollBy({
      left: -(ref.current?.offsetWidth - paddingLeft - nextCellOffset + gutter),
      behavior: 'smooth',
    });
  }, [gutter, nextCellOffset, paddingLeft]);

  const scrollRight = useCallback(() => {
    if (!ref.current) return;

    ref.current.style.scrollSnapType = 'none';

    ref.current.scrollBy({
      left: ref.current?.offsetWidth - paddingLeft - nextCellOffset + gutter,
      behavior: 'smooth',
    });
  }, [gutter, nextCellOffset, paddingLeft]);

  const originalFeedEntriesLength: number = feed?.entry?.length || 0;
  const feedEntriesLength: number = feedEntries?.length || 0;

  const shouldDuplicateEntries: boolean =
    circularCondition &&
    originalFeedEntriesLength === feedEntriesLength &&
    originalFeedEntriesLength * 2 !== feedEntriesLength;

  useDuplicatedFeedEntry({
    feedEntries,
    setFeedEntries,
    isLoading,
    circularCondition,
    originalFeedEntriesLength,
    shouldDuplicateEntries,
  });

  const isCircularEntriesLoading: boolean =
    useHorizontalListCircularScrollInstantScrollOnInitialLoad({
      ref,
      cellRef,
      gutter,
      circularCondition,
      scrollLeft,
      shouldDuplicateEntries,
      originalFeedEntriesLength,
      feedEntries,
      isLoading,
      setIsEntriesLoading,
      isEntriesLoading,
    });

  const indicatorsEntries: FeedEntry[] = entries.slice(0, itemLimit);

  useUpdateIndicatorsIndexOnScroll({
    ref,
    cellRef,
    indicators_switch,
    circularCondition,
    feedEntry: indicatorsEntries,
    autoscrollSwitch,
    loopSwitch,
    setCurrentIndicatorsIndex,
    isCircularEntriesLoading,
  });

  useHorizontalListAutoScroll({
    ref,
    feed,
    isLoading,
    scrollRight,
    feedEntries,
    cellRef,
    gutter,
    autoscrollSwitch,
    autoscrollInterval,
    loopSwitch,
  });

  const translations = getTranslations(uiComponent?.localizations);
  const titleOverride = translations?.title_override;

  return (
    <div
      className={`component-${uiComponent?.id} horizontal-list-parent-classes relative flex flex-shrink flex-grow flex-col`}
    >
      <div
        ref={ref}
        className="horizontal-list-classes flex snap-mandatory flex-row overflow-x-scroll scroll-smooth scrollbar-hide"
        data-testid={`horizontal-list-container-${uiComponent?.id}`}
      >
        {!hideButtonsBasedOnCellsInView && arrowsSwitch && (
          <HorizontalListButton
            onClick={() => {
              triggerScrollAction({
                ref,
                cellRef,
                gutter,
                feedEntriesLength: feedEntries?.length || 0,
                circularCondition,
                scrollRight,
                scrollLeft,
                direction: 'left',
              });
            }}
            direction="left"
            show={(showLeftButton && !isLoading) || circularCondition}
            uiComponent={uiComponent}
            isNextFeedLoading={isNextFeedLoading}
            position={horizontalListButtonPosition}
          />
        )}
        <div
          ref={cellContainerRef}
          className={`z-0 flex w-full flex-row`}
          data-horizontal-list-container
        >
          {entries?.map((entry, i) => {
            const title = titleOverride || entry?.title;
            const cellEntry = { ...entry, title };

            return (
              <div
                className={cn(
                  entries.length - 1 !== i ? 'horizontal-list-gutter' : '',
                  'opacity-1 shrink-0 snap-both snap-always',
                  {
                    'horizontal-list-cell-width': cellScalingSwitch,
                  },
                  getHorizontalListScrollSnapClasses(styles, cellScalingSwitch)
                )}
                key={cellEntry?._key || `${uiComponent?.id}-${i}`}
              >
                <PowerCell
                  cellRef={cellRef}
                  uiComponent={uiComponent}
                  entry={cellEntry}
                  selectable={uiComponent?.rules?.cells_selectable_switch}
                  cellClassNames={cellScalingSwitch ? 'w-full' : ''}
                  isLoading={isLoading || isCircularEntriesLoading}
                />
              </div>
            );
          })}
          {cellScalingSwitch && <div className="hl-padding-fix" />}
        </div>
        {!hideButtonsBasedOnCellsInView && arrowsSwitch && (
          <HorizontalListButton
            onClick={() => {
              triggerScrollAction({
                ref,
                cellRef,
                gutter,
                feedEntriesLength: feedEntries?.length || 0,
                circularCondition,
                scrollRight,
                scrollLeft,
                direction: 'right',
              });
            }}
            direction="right"
            show={
              (showRightButton && !isLoading) ||
              isNextFeedLoading ||
              circularCondition
            }
            uiComponent={uiComponent}
            isNextFeedLoading={isNextFeedLoading}
            position={horizontalListButtonPosition}
          />
        )}
      </div>
      <HorizontalListIndicators
        show={
          indicators_switch &&
          !isLoading &&
          Array.isArray(indicatorsEntries) &&
          Number(indicatorsEntries?.length) > 0
        }
        cellContainerRef={cellContainerRef}
        entries={indicatorsEntries as FeedEntry[]}
        alignment={indicatorsAlignment}
        position={indicators_position}
        arrowsSwitch={indicator_arrows_switch}
        arrowLeftAsset={indicator_arrow_left_asset_default}
        arrowRightAsset={indicator_arrow_right_asset_default}
        circularCondition={circularCondition}
        scrollLeft={() => {
          triggerScrollAction({
            ref,
            cellRef,
            gutter,
            feedEntriesLength: feedEntries?.length || 0,
            circularCondition,
            scrollRight,
            scrollLeft,
            direction: 'left',
          });
        }}
        scrollRight={() => {
          triggerScrollAction({
            ref,
            cellRef,
            gutter,
            feedEntriesLength: feedEntries?.length || 0,
            circularCondition,
            scrollRight,
            scrollLeft,
            direction: 'right',
          });
        }}
        currentIndicatorsIndex={currentIndicatorsIndex}
        setCurrentIndicatorsIndex={setCurrentIndicatorsIndex}
        uiComponentId={uiComponent?.id}
      />
    </div>
  );
}
